import React, { } from 'react'
import style from './Index.module.less'
import { Form, Input, Row, Col } from 'antd'

const { Item } = Form

export function formatUpdateData (d) {
  if (typeof d.bank !== 'string' || d.bank.length === 0) {
    d.bank = null
  }
  if (typeof d.bank_accountno !== 'string' || d.bank_accountno.length === 0) {
    d.bank_accountno = null
  }
}

export function initFormData (data, result) {
  const keys = ['bank', 'bank_accountno']
  keys.forEach(k => {
    if (data[k] !== undefined && data[k] !== null) {
      result[k] = data[k]
    }
  })
}

function Main () {
  return (
    <div>
      <div className={style.title}>报酬接收账号</div>
      <Row gutter={24}>
        <Col span={12}>
          <Item label='开户行' name='bank'>
            <Input placeholder='请输入' allowClear />
          </Item>
        </Col>
        <Col span={12}>
          <Item label='银行卡号' name='bank_accountno'>
            <Input placeholder='请输入' allowClear />
          </Item>
        </Col>
      </Row>
    </div>
  )
}

export default Main
