import React from 'react'
import moment from 'moment'
import { pk } from './config'
import NodeRSA from 'node-rsa'
import { JSEncrypt } from 'jsencrypt'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { message, Modal } from 'antd'

const { confirm } = Modal

// await 错误处理方法
export async function awaitWrap (promise) {
  return await promise
    .then((data) => [null, data])
    .catch(err => [err, null])
}

export function formatDate (v, format = 'YYYY-MM-DD') {
  if (typeof v === 'number' && v > 0) {
    return moment(v * 1000).format(format)
  }
  return v
}

export function limitStr (s, n) {
  if (s.length <= n) {
    return s
  }
  return `${s.slice(0, n)}...`
}

// 公钥加密
export function compress (v) {
  // const key = new NodeRSA(pk, undefined, { encryptionScheme: 'pkcs1' })
  // const str = key.encrypt(v, 'base64')
  try {
    const encryptTool = new JSEncrypt()
    encryptTool.setPublicKey(pk)
    const str = encryptTool.encrypt(v)
    if (typeof str === 'string') {
      return str
    }
  } catch (e) {
    console.error(e)
  }
  return ''
}

// 公钥解密
export function decompress (v) {
  try {
    if (typeof v === 'string' && v) {
      const key = new NodeRSA()
      key.importKey(pk)
      const str = key.decryptPublic(v, 'utf8')
      if (typeof str === 'string') {
        return str
      }
    }
  } catch (e) {
    console.error(e)
  }
  return v || ''
}

// 格式化案件件证件号
export function formatCaseDataLicenseAndMobile (d) {
  const keys = ['applicant_agents', 'applicants', 'respondents', 'respondents_agents']
  keys.forEach(key => {
    const arr = d[key]
    if (arr instanceof Array && arr.length > 0) {
      arr.forEach(li => {
        if (typeof li.license === 'string' && li.license.length > 0) {
          try {
            li.license = decompress(li.license)
          } catch (e) {
            console.error(e)
          }
        }
        if (typeof li.mobile === 'string' && li.mobile.length > 0) {
          try {
            li.mobile = decompress(li.mobile)
          } catch (e) {
            console.error(e)
          }
        }
      })
    }
  })
  return d
}

export function compressLicense (o) {
  if (o && typeof o.license === 'string' && o.license.length > 0) {
    o.license = compress(o.license)
  }
}

export function decompressLicense (o) {
  if (o && typeof o.license === 'string' && o.license.length > 0) {
    o.license = decompress(o.license)
  }
}

export function compressListLicense (l) {
  if (l instanceof Array && l.length > 0) {
    l.forEach(li => {
      compressLicense(li)
    })
  }
}

export function decomporessListLicense (l) {
  if (l instanceof Array && l.length > 0) {
    l.forEach(li => {
      decompressLicense(li)
    })
  }
}

// 判断是否未空文本
export function filterEmptyText (v, unit = '无') {
  return v === undefined || v === '' || v === null ? unit : v
}

// 是否为null、undefind或者空字符串
export function isEmptyString (v) {
  return v === '' || v === null || v === undefined
}

// 二次确认
export function confirmAction (content, callback) {
  confirm({
    icon: <ExclamationCircleOutlined />,
    content,
    onOk () {
      callback()
    }
  })
}

// 获取文件上传的配置
export function getUploadPropsFn (formRef, accept, fileList, setFileList, multiple) {
  return {
    onRemove: file => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file, files) => {
      if (accept) {
        if (multiple) {
          for (let j = 0; j < files.length; j++) {
            const l = accept.split(',')
            const i = files[j].name.lastIndexOf('.')
            const n = files[j].name.slice(i)
            if (!l.includes(n)) {
              message.warning(`请选择${l}的文件`)
              return false
            }
            setFileList([...fileList, ...files])
          }
        } else {
          const l = accept.split(',')
          const i = file.name.lastIndexOf('.')
          const n = file.name.slice(i)
          if (!l.includes(n)) {
            message.warning(`请选择${l}的文件`)
            return false
          }
          setFileList([...fileList, file])
        }
      } else {
        if (multiple) {
          setFileList([...fileList, ...files])
        } else {
          setFileList([...fileList, file])
        }
      }
      return false
    },
    fileList,
    accept
  }
}
