import { $get, $put } from '@/assets/js/authRequest'
import { message } from 'antd'

export async function getListRequest (text) {
  const res = await $get('/api/arbitrator//researchtag', {
    params: {
      condition: JSON.stringify([{ k: 'title', o: 'like', v: `%${text}%` }])
    }
  })
  if (res.status !== 1) {
    message.error(res.message)
    throw new Error(res.message)
  }
  return res.data
}

export async function saveAllTagsRequest (title) {
  const res = await $put('/api/arbitrator//researchtag/saveall', { title })
  if (res.status !== 1) {
    message.error(res.message)
    throw new Error(res.message)
  }
  return res.data
}
