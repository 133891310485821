import React from 'react'
import style from './Index.module.less'
import { Form, Input, Row, Col, Select } from 'antd'

const { Item } = Form
const { Option } = Select

export function formatUpdateData (d) {
  return d
}

export function initFormData (data, result) {
  const keys = ['structure_tel', 'tel', 'mobile', 'fax', 'wechat', 'email', 'structure_address', 'other_address', 'first_address']
  keys.forEach(k => {
    if (data[k] !== undefined && data[k] !== null) {
      result[k] = data[k]
    }
  })
}

function Main () {
  return (
    <div>
      <div className={style.title}>联系方式</div>
      <Row gutter={24}>
        <Col span={12}>
          <Item label='手机号码' name='mobile'>
            <Input placeholder='请输入' allowClear />
          </Item>
        </Col>
        <Col span={12}>
          <Item label='单位电话' name='structure_tel'>
            <Input placeholder='请输入' allowClear />
          </Item>
        </Col>
        <Col span={12}>
          <Item label='家庭电话' name='tel'>
            <Input placeholder='请输入' allowClear />
          </Item>
        </Col>
        <Col span={12}>
          <Item label='传真号码' name='fax'>
            <Input placeholder='请输入' allowClear />
          </Item>
        </Col>
        <Col span={12}>
          <Item label='电子邮箱' name='email'>
            <Input placeholder='请输入' allowClear />
          </Item>
        </Col>
        <Col span={12}>
          <Item shouldUpdate={(prev, cur) => prev.first_address !== cur.first_address}>
            {
              ({ getFieldValue }) => {
                const c = getFieldValue('first_address')
                return (
                  <Item label='单位地址' name='structure_address' rules={[{ required: c === '单位', message: '单位地址不能为空' }]}>
                    <Input placeholder='请输入' allowClear />
                  </Item>
                )
              }
            }
          </Item>
        </Col>
        <Col span={12}>
          <Item label='其他地址' name='other_address'>
            <Input placeholder='请输入' allowClear />
          </Item>
        </Col>
        <Col span={12}>
          <Item label='首选通讯地址' name='first_address'>
            <Select placeholder='请选择' allowClear>
              {['家庭', '单位', '其他'].map((li, i) => {
                return (
                  <Option key={i} value={li}>{li}</Option>
                )
              })}
            </Select>
          </Item>
        </Col>
      </Row>
    </div>
  )
}

export default Main
