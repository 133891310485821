import React, { useEffect, useMemo, useState } from 'react'
import style from './Index.module.less'
import { Form, Input, Row, Col, Button, Select, DatePicker, Upload, message } from 'antd'
import { PlusOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons'
import Dictionary from '@/assets/js/dictionary'
import moment from 'moment'
import { getUploadPropsFn } from '@/assets/js/tool'
import { submitFilesAndReturnFiles } from '@/assets/js/request'
import classNames from 'classnames'

const { Item, List } = Form
const { Option } = Select

export async function formatUpdateData (d) {
  if (d.education_info instanceof Array) {
    const list = d.education_info
    for (let i = 0; i < list.length; i++) {
      const o = list[i]
      if (o.begin_datetime instanceof moment) {
        o.begin_datetime = o.begin_datetime.format('YYYY-MM')
      }
      if (o.end_datetime instanceof moment) {
        o.end_datetime = o.end_datetime.format('YYYY-MM')
      }
      await uploadFile(o)
    }
  }
}

export function initFormData (data, result, setFileMap) {
  const o = {}
  if (data.education_info instanceof Array) {
    result.education_info = data.education_info.map((li, i) => {
      o[i] = li.file instanceof Array ? li.file : []
      return {
        ...li,
        begin_datetime: moment(li.begin_datetime),
        end_datetime: moment(li.end_datetime)
      }
    })
  }
  setFileMap(o)
}

async function uploadFile (d) {
  let file = []
  if (d.file) {
    if (d.file instanceof Array) {
      file = d.file
    } else if (d.file.fileList && d.file.fileList instanceof Array) {
      file = d.file.fileList
    }
  }
  if (file.length && file.filter(li => !li.id).length) {
    const hide = message.loading('上传中')
    try {
      let fs = await submitFilesAndReturnFiles(file)
      fs = fs.map((f, i) => {
        if (typeof f === 'string') {
          return file[i]
        }
        f.name = file[i].name
        return f
      })
      d.file = fs
      d.fileid = d.file.map(li => li.id).join(',')
      hide()
    } catch (e) {
      hide()
      return message.warning('上传图片失败')
    }
  }
}

function Main ({ formRef, fileMap, setFileMap, fields }) {
  const [educationList, setEducationList] = useState([])
  const visible = useMemo(() => {
    return fields.includes('education_info')
  }, [fields])
  useEffect(() => {
    const d = new Dictionary()
    d.init('学历')
      .then(() => {
        setEducationList(d.getList('学历').map(li => li.value))
      })
      .catch(e => console.error(e))
  }, [])
  return (
    <div className={classNames({ [style.hide]: !visible })}>
      <div className={style.title}>教育背景</div>
      <List name='education_info'>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              return (
                <Row key={key} gutter={24}>
                  <Col span={24}>
                    <div className={style['block-title']}>
                      <span>教育背景{name + 1}</span>
                      <Button shape='circle' icon={<DeleteOutlined />} onClick={() => { remove(name); const o = { ...fileMap }; delete o[key]; setFileMap(o) }} />
                    </div>
                  </Col>
                  <Col span={24}>
                    <Item name={[name, 'university']} label='学校' {...restField} rules={[{ required: true, message: '学校不能为空' }]}>
                      <Input placeholder='请输入' allowClear />
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item name={[name, 'educational']} label='学历' {...restField} rules={[{ required: true, message: '学历不能为空' }]}>
                      <Select placeholder='请选择' allowClear>
                        {educationList.map((li, i) => {
                          return <Option key={i} value={li}>{li}</Option>
                        })}
                      </Select>
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item name={[name, 'major']} label='专业' {...restField} rules={[{ required: true, message: '专业不能为空' }]}>
                      <Input placeholder='请输入' allowClear />
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item name={[name, 'begin_datetime']} label='开始时间' {...restField} rules={[{ required: true, message: '开始时间不能为空' }]}>
                      <DatePicker placeholder='请选择' picker='month' className={style.full} />
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item name={[name, 'end_datetime']} label='结束时间' {...restField}>
                      <DatePicker placeholder='至今' picker='month' className={style.full} />
                    </Item>
                  </Col>
                  <Col span={24}>
                    <Item name={[name, 'file']} label='学历学位证书' rules={[{ required: true, message: '学历学位证书不能为空' }]}>
                      <Upload {...getUploadPropsFn.call(this, formRef, '', fileMap[key] || [], (v) => setFileMap({ ...fileMap, [key]: v }))}>
                        <Button>
                          <UploadOutlined /> 上传文件
                        </Button>
                      </Upload>
                    </Item>
                  </Col>
                </Row>
              )
            })}
            <Col span={24}>
              <Item>
                <Button block type='dashed' icon={<PlusOutlined />} onClick={() => add()}>添加</Button>
              </Item>
            </Col>
          </>
        )}
      </List>
    </div>
  )
}

export default Main
