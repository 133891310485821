import React from 'react'
import style from './Index.module.less'
import { Form, Input, Row, Col } from 'antd'

const { Item } = Form
const { TextArea } = Input

export function formatUpdateData (d) {
  if (typeof d.achievement !== 'string' || d.achievement.length === 0) {
    d.achievement = null
  }
}

export function initFormData (data, result) {
  const keys = ['achievement']
  keys.forEach(k => {
    if (data[k] !== undefined && data[k] !== null) {
      result[k] = data[k]
    }
  })
}

function Main () {
  return (
    <div>
      <div className={style.title}>学术成果</div>
      <Row gutter={24}>
        <Col span={24}>
          <Item label='学术成果' name='achievement'>
            <TextArea placeholder='请输入' autoSize={{ minRows: 3 }} allowClear />
          </Item>
        </Col>
      </Row>
    </div>
  )
}

export default Main
