import React, { useEffect, useState } from 'react'
import style from './Index.module.less'
import { Form, TreeSelect, Row, Col, Alert } from 'antd'
import Text from '@/components/Text'
import ProfessionalTags from '@/assets/js/professionalTags'
import Tree from '@/components/Tree/Index'

const { Item } = Form

export function formatUpdateData (d) {
  if (d.professional_tag instanceof Array && d.professional_tag.length > 0) {
    d.professional_tag = d.professional_tag.join()
  }
}

export function initFormData (data, result) {
  if (typeof data.professional_tag === 'string' && data.professional_tag.length > 0) {
    const l = data.professional_tag.split(',').filter(li => li)
    result.professional_tag = l
  }
}

function TreeShow ({ professionalTags }) {
  return (
    <Row gutter={24}>
      <Col span={24}>
        <Item label='已选专业' colon={false} name='professional_tag' className={style['tree-label']}>
          <Text format={v => {
            if (!(v instanceof Array) || !professionalTags) {
              return '无'
            }
            professionalTags.initIdsToTreeList(v.join())
            return <Tree data={professionalTags.currentList} />
          }}
          />
        </Item>
      </Col>
    </Row>
  )
}

function Main ({ treeData }) {
  const [professionalTags, setProfessionalTags] = useState(null)
  useEffect(() => {
    if (treeData) {
      setProfessionalTags(new ProfessionalTags(treeData))
    }
  }, [treeData])
  return (
    <div>
      <div className={style.title}>擅长专业</div>
      <div className={style.notice}>
        <Alert description='注意：“专业大项”和“专业小项”为必选项，请选择三项以内“专业大项”和不少于三项的“专业小项”。“专业细项”选择项数不限，不选择视为全选。此外，如您有擅长的研究领域，可在“研究领域”中以关键词形式进行填写，便于通过关键词匹配案件合适的仲裁员。' type='warning' showIcon />
      </div>
      <TreeShow professionalTags={professionalTags} />
      <Row gutter={24}>
        <Col span={24}>
          <Item label='擅长专业' name='professional_tag' rules={[{ required: true, message: '擅长专业不能为空' }]}>
            <TreeSelect treeData={treeData} placeholder='请选择' allowClear showSearch multiple fieldNames={{ label: 'title', value: 'id', children: 'children' }} />
          </Item>
        </Col>
      </Row>
    </div>
  )
}

export default Main
