import React, { useState, useEffect, useMemo } from 'react'
import style from './Index.module.less'
import { Form, Row, Col, Input, Select, DatePicker, Radio, Cascader } from 'antd'
import Dictionary from '@/assets/js/dictionary'
import { politicalList } from '@/assets/js/config'
import { getProvinceCityTree } from '@/assets/js/area'
import moment from 'moment'
import classNames from 'classnames'

const { Item } = Form
const { Option } = Select
const provinceCitys = getProvinceCityTree()
const allFields = ['name', 'license_type', 'license', 'gender', 'birthday', 'native_place', 'country', 'province', 'city', 'address', 'nation', 'academic_title', 'political', 'language', 'retire', 'iscivilservant']
export function initFormData (data, result) {
  const keys = ['name', 'license_type', 'license', 'gender', 'native_place', 'country', 'address', 'nation', 'academic_title', 'political', 'language', 'retire', 'iscivilservant']
  keys.forEach(k => {
    if (data[k] !== undefined && data[k] !== null) {
      result[k] = data[k]
    }
  })
  if (typeof data.birthday === 'string' && data.birthday.length > 0) {
    result.birthday = moment(data.birthday, 'YYYY-MM')
  }
  if (data.province && data.city) {
    result.provinceCity = [data.province, data.city]
  }
}

export function formatUpdateData (d) {
  if (d.birthday instanceof moment) {
    d.birthday = d.birthday.format('YYYY-MM')
  }
  if (d.provinceCity instanceof Array && d.provinceCity.length > 0) {
    d.province = d.provinceCity[0]
    d.city = d.provinceCity[1]
  }
  delete d.provinceCity
  return d
}

function Main ({ fields }) {
  const [licenseTypes, setLicenseTypes] = useState([])
  const [countries, setCountries] = useState([])
  const fieldMap = useMemo(() => {
    const o = {}
    allFields.forEach(key => {
      o[key] = false
    })
    fields.forEach(key => {
      if (allFields.includes(key)) {
        o[key] = true
      }
    })
    return o
  }, [fields])
  const visible = useMemo(() => {
    return allFields.some(key => fieldMap[key])
  }, [fieldMap])
  useEffect(() => {
    const d = new Dictionary()
    d.init('自然人证件类型,国家')
      .then(() => {
        setLicenseTypes(d.getList('自然人证件类型').map(li => li.title))
        setCountries(d.getList('国家').map(li => li.title))
      })
      .catch(e => console.error(e))
  }, [])
  return (
    <div className={classNames({ [style.hide]: !visible })}>
      <div className={style.title}>个人信息</div>
      <Row gutter={24}>
        {
          fieldMap.name && (
            <Col span={12}>
              <Item label='姓名' name='name' rules={[{ required: true, message: '姓名不能为空' }]}>
                <Input placeholder='请输入' allowClear />
              </Item>
            </Col>
          )
        }
        {
          fieldMap.license_type && (
            <Col span={12}>
              <Item label='证件类型' name='license_type' rules={[{ required: true, message: '证件类型不能为空' }]}>
                <Select placeholder='请选择' allowClear>
                  {licenseTypes.map((li, i) => {
                    return (
                      <Option key={i} value={li}>{li}</Option>
                    )
                  })}
                </Select>
              </Item>
            </Col>
          )
        }
        {
          fieldMap.license && (
            <Col span={12}>
              <Item label='证件号码' name='license' rules={[{ required: true, message: '证件号码不能为空' }]}>
                <Input placeholder='请输入' allowClear maxLength={18} />
              </Item>
            </Col>
          )
        }
        {
          fieldMap.gender && (
            <Col span={12}>
              <Item label='性别' name='gender' rules={[{ required: true, message: '性别不能为空' }]}>
                <Select placeholder='请选择' allowClear>
                  <Option value='男'>男</Option>
                  <Option value='女'>女</Option>
                </Select>
              </Item>
            </Col>
          )
        }
        {
          fieldMap.birthday && (
            <Col span={12}>
              <Item label='出生年月' name='birthday' rules={[{ required: true, message: '出生年月不能为空' }]}>
                <DatePicker style={{ width: '100%' }} picker='month' />
              </Item>
            </Col>
          )
        }
        {
          fieldMap.native_place && (
            <Col span={12}>
              <Item label='籍贯' name='native_place' rules={[{ required: true, message: '籍贯不能为空' }]}>
                <Input placeholder='请输入' allowClear />
              </Item>
            </Col>
          )
        }
        {
          fieldMap.country && (
            <Col span={12}>
              <Item label='国家' name='country' rules={[{ required: true, message: '国家不能为空' }]}>
                <Select placeholder='请选择' allowClear>
                  {countries.map((li, i) => {
                    return (
                      <Option key={i} value={li}>{li}</Option>
                    )
                  })}
                </Select>
              </Item>
            </Col>
          )
        }
        {
          (fieldMap.city || fieldMap.province || fieldMap.address) && (
            <>
              <div />
              <Col span={12}>
                <Item label='家庭地址' name='provinceCity' rules={[{ required: true, message: '家庭地址不能为空' }]}>
                  <Cascader options={provinceCitys} allowClear placeholder='请选择' />
                </Item>
              </Col>
              <Col span={12}>
                <Item label=' ' name='address'>
                  <Input placeholder='请输入家庭详细地址' allowClear />
                </Item>
              </Col>
            </>
          )
        }
        {
          fieldMap.nation && (
            <Col span={12}>
              <Item label='民族' name='nation' rules={[{ required: true, message: '民族不能为空' }]}>
                <Input placeholder='请输入' allowClear />
              </Item>
            </Col>
          )
        }
        {
          fieldMap.academic_title && (
            <Col span={12}>
              <Item label='职称' name='academic_title' rules={[{ required: true, message: '职称不能为空' }]}>
                <Input placeholder='请输入' allowClear />
              </Item>
            </Col>
          )
        }
        {
          fieldMap.political && (
            <Col span={12}>
              <Item label='政治面貌' name='political' rules={[{ required: true, message: '政治面貌不能为空' }]}>
                <Select placeholder='请选择' allowClear>
                  {politicalList.map((li, i) => {
                    return (
                      <Option key={i} value={li}>{li}</Option>
                    )
                  })}
                </Select>
              </Item>
            </Col>
          )
        }
        {
          fieldMap.language && (
            <Col span={12}>
              <Item label='工作语言' name='language' rules={[{ required: true, message: '工作语言不能为空' }]}>
                <Input placeholder='请输入' allowClear />
              </Item>
            </Col>
          )
        }
        {
          fieldMap.retire && (
            <Col span={12}>
              <Item label='是否在职' name='retire' rules={[{ required: true, message: '是否在职不能为空' }]}>
                <Select placeholder='请选择' allowClear>
                  <Option value={0}>在职</Option>
                  <Option value={1}>非在职</Option>
                </Select>
              </Item>
            </Col>
          )
        }
        {
          fieldMap.iscivilservant && (
            <Col span={12}>
              <Item label='在职公务员' name='iscivilservant' rules={[{ required: true, message: '在职公务员不能为空' }]}>
                <Radio.Group options={[{ label: '是', value: 1 }, { label: '否', value: 0 }]} />
              </Item>
            </Col>
          )
        }
      </Row>
    </div>
  )
}

export default Main
