import React, { useMemo } from 'react'
import style from './Index.module.less'
import { Form, Input, Row, Col, Select } from 'antd'
import classNames from 'classnames'

const { Item } = Form
const { Option } = Select
const allFields = ['structure_tel', 'tel', 'mobile', 'fax', 'wechat', 'email', 'structure_address', 'other_address', 'first_address']
export function formatUpdateData (d) {
  return d
}

export function initFormData (data, result) {
  const keys = ['structure_tel', 'tel', 'mobile', 'fax', 'wechat', 'email', 'structure_address', 'other_address', 'first_address']
  keys.forEach(k => {
    if (data[k] !== undefined && data[k] !== null) {
      result[k] = data[k]
    }
  })
}

function Main ({ fields }) {
  const fieldMap = useMemo(() => {
    const o = {}
    allFields.forEach(key => {
      o[key] = false
    })
    fields.forEach(key => {
      if (allFields.includes(key)) {
        o[key] = true
      }
    })
    return o
  }, [fields])
  const visible = useMemo(() => {
    return allFields.some(key => fieldMap[key])
  }, [fieldMap])
  return (
    <div className={classNames({ [style.hide]: !visible })}>
      <div className={style.title}>联系方式</div>
      <Row gutter={24}>
        {
          fieldMap.mobile && (
            <Col span={12}>
              <Item label='手机号码' name='mobile' rules={[{ required: true, message: '手机号码不能为空' }]}>
                <Input placeholder='请输入' allowClear />
              </Item>
            </Col>
          )
        }
        {
          fieldMap.structure_tel && (
            <Col span={12}>
              <Item label='单位电话' name='structure_tel' rules={[{ required: true, message: '单位电话不能为空' }]}>
                <Input placeholder='请输入' allowClear />
              </Item>
            </Col>
          )
        }
        {
          fieldMap.tel && (
            <Col span={12}>
              <Item label='家庭电话' name='tel' rules={[{ required: true, message: '家庭电话不能为空' }]}>
                <Input placeholder='请输入' allowClear />
              </Item>
            </Col>
          )
        }
        {
          fieldMap.fax && (
            <Col span={12}>
              <Item label='传真号码' name='fax' rules={[{ required: true, message: '传真号码不能为空' }]}>
                <Input placeholder='请输入' allowClear />
              </Item>
            </Col>
          )
        }
        {
          fieldMap.email && (
            <Col span={12}>
              <Item label='电子邮箱' name='email' rules={[{ required: true, message: '电子邮箱不能为空' }]}>
                <Input placeholder='请输入' allowClear />
              </Item>
            </Col>
          )
        }
        {
          fieldMap.structure_address && (
            <Col span={12}>
              <Item noStyle shouldUpdate={(prev, cur) => prev.first_address !== cur.first_address}>
                {({ getFieldValue }) => {
                  const c = getFieldValue('first_address')
                  return (
                    <Item label='单位地址' name='structure_address' rules={[{ required: c === '单位', message: '单位地址不能为空' }]}>
                      <Input placeholder='请输入' allowClear />
                    </Item>
                  )
                }}
              </Item>
            </Col>
          )
        }
        {
          fieldMap.other_address && (
            <Col span={12}>
              <Item label='其他地址' name='other_address' rules={[{ required: true, message: '其他地址不能为空，如没有则填“无”' }]}>
                <Input placeholder='请输入' allowClear />
              </Item>
            </Col>
          )
        }
        {
          fieldMap.first_address && (
            <Col span={12}>
              <Item label='首选通讯地址' name='first_address' rules={[{ required: true, message: '首选通讯地址不能为空' }]}>
                <Select placeholder='请选择' allowClear>
                  {['家庭', '单位', '其他'].map((li, i) => {
                    return (
                      <Option key={i} value={li}>{li}</Option>
                    )
                  })}
                </Select>
              </Item>
            </Col>
          )
        }
      </Row>
    </div>
  )
}

export default Main
