import React, { useMemo } from 'react'
import style from './Index.module.less'
import { Form, Input, Row, Col } from 'antd'
import classNames from 'classnames'

const { Item } = Form
const allFields = ['bank', 'bank_accountno']
export function formatUpdateData (d) {
  if (typeof d.bank !== 'string' || d.bank.length === 0) {
    d.bank = null
  }
  if (typeof d.bank_accountno !== 'string' || d.bank_accountno.length === 0) {
    d.bank_accountno = null
  }
}

export function initFormData (data, result) {
  const keys = ['bank', 'bank_accountno']
  keys.forEach(k => {
    if (data[k] !== undefined && data[k] !== null) {
      result[k] = data[k]
    }
  })
}

function Main ({ fields }) {
  const fieldMap = useMemo(() => {
    const o = {}
    allFields.forEach(key => {
      o[key] = false
    })
    fields.forEach(key => {
      if (allFields.includes(key)) {
        o[key] = true
      }
    })
    return o
  }, [fields])
  const visible = useMemo(() => {
    return allFields.some(key => fieldMap[key])
  }, [fieldMap])
  return (
    <div className={classNames({ [style.hide]: !visible })}>
      <div className={style.title}>报酬接收账号</div>
      <Row gutter={24}>
        {
          fieldMap.bank && (
            <Col span={12}>
              <Item label='开户行' name='bank'>
                <Input placeholder='请输入' allowClear />
              </Item>
            </Col>
          )
        }
        {
          fieldMap.bank_accountno && (
            <Col span={12}>
              <Item label='银行卡号' name='bank_accountno'>
                <Input placeholder='请输入' allowClear />
              </Item>
            </Col>
          )
        }
      </Row>
    </div>
  )
}

export default Main
