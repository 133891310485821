import { $get, $put } from '@/assets/js/authRequest'
import { decompress } from '@/assets/js/tool'
import { message } from 'antd'

export async function getDataRequest () {
  const res = await $get('/api/arbitrator/me/getMyAnnual')
  if (res.status !== 1) {
    message.error(res.message)
    throw new Error(res.message)
  }
  if (res.data) {
    if (res.data.mobile && typeof res.data.mobile === 'string') {
      res.data.mobile = decompress(res.data.mobile)
    }
    if (res.data.license && typeof res.data.license === 'string') {
      res.data.license = decompress(res.data.license)
    }
    if (res.data.bank_accountno && typeof res.data.bank_accountno === 'string') {
      res.data.bank_accountno = decompress(res.data.bank_accountno)
    }
    try {
      if (typeof res.data.education_info === 'string' && res.data.education_info) {
        res.data.education_info = JSON.parse(res.data.education_info)
      }
      if (res.data.qualification_info && typeof res.data.qualification_info === 'string') {
        res.data.qualification_info = JSON.parse(res.data.qualification_info)
      }
      if (res.data.structure_log && typeof res.data.structure_log === 'string') {
        res.data.structure_log = JSON.parse(res.data.structure_log)
      }
    } catch (e) {
      console.error(e)
    }
  }
  return res.data
}

export async function updateDataRequest (d) {
  const res = await $put('/api/arbitrator/me/updateMyAnnual', d)
  if (res.status !== 1) {
    message.error(res.message)
    throw new Error(res.message)
  }
  return res.data
}

export async function getProfessionalTagsRequest () {
  const res = await $get('/api/arbitrator//professionaltag')
  if (res.status !== 1) {
    message.error(res.message)
    throw new Error(res.message)
  }
  return res.data
}

// 获取年审字段
export async function getFieldsRequest () {
  const res = await $get('/api/arbitrator/me/geAnnualField')
  if (typeof res.data === 'string' && res.data) {
    return res.data.split(',').filter(li => li)
  }
  return []
}
