import React, { useMemo } from 'react'
import style from './Index.module.less'
import { DownOutlined } from '@ant-design/icons'
import classNames from 'classnames'

function Node ({ data }) {
  const hasChild = useMemo(() => {
    return (data.children || []).length > 0
  }, [data])
  const list = useMemo(() => {
    if (data.children instanceof Array) {
      return data.children
    }
    return []
  }, [data])
  return (
    <div className={classNames(style.node, { [style.nochild]: !hasChild })}>
      {hasChild ? <div className={style.icon}><DownOutlined /></div> : <span className={style.line} />}
      <div className={style['node-title']}>{data.title}</div>
      <div className={style.children}>
        {list.map((li, i) => {
          return (
            <Node data={li} key={i} />
          )
        })}
      </div>
    </div>
  )
}

function Main ({ data }) {
  return (
    <div className={style.container}>
      <div className={classNames(style.children, style.total)}>
        {
          data.map((li, i) => {
            return (
              <Node key={i} data={li} />
            )
          })
        }
      </div>
    </div>
  )
}

export default Main
