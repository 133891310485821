import _ from 'lodash'
function noEmpty (v) {
  if (v instanceof Array) {
    return v.length !== 0
  }
  if (v instanceof Object && v !== null) {
    if (v.fileList instanceof Array && v.fileList.length === 0) {
      return false
    }
  }
  return v !== null && v !== undefined && v !== ''
}

function changeRequired_ (formRef, index, requiredList, setRequiredList) {
  const c = formRef.current
  const list = [...requiredList]
  if (!c) {
    return undefined
  }
  const d = c.getFieldsValue()
  const name = `qualification0${index + 1}`
  const o = d[name]
  if (!o) {
    list[index] = false
  } else {
    const keys = Object.keys(o)
    list[index] = keys.some(k => noEmpty(o[k]))
  }
  setRequiredList(list)
  if (!list[index]) {
    setTimeout(() => {
      c.setFieldsValue({ [name]: undefined })
    }, 0)
  }
}

export const changeRequired = _.debounce(changeRequired_, 100)

export function changeAllRequired (data, setRequiredList) {
  const fields = ['qualification01', 'qualification02', 'qualification03', 'qualification04', 'qualification05']
  const list = []
  fields.forEach(field => {
    const o = data[field]
    if (!o) {
      list.push(false)
    } else {
      const keys = Object.keys(o)
      list.push(keys.some(k => noEmpty(o[k])))
    }
  })
  setRequiredList(list)
}
