import React, { useEffect, useMemo, useState } from 'react'
import style from './Index.module.less'
import { Form, Input, Row, Col, Button, Select, DatePicker, Radio } from 'antd'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import Dictionary from '@/assets/js/dictionary'
import moment from 'moment'
import classNames from 'classnames'

const { Item, List } = Form
const { Option } = Select

export function formatUpdateData (d) {
  if (d.structure_log instanceof Array) {
    d.structure_log.forEach(li => {
      if (li.begin_datetime instanceof moment) {
        li.begin_datetime = Math.floor(li.begin_datetime.valueOf() / 1000)
      }
      if (li.end_datetime instanceof moment) {
        li.end_datetime = Math.floor(li.end_datetime.valueOf() / 1000)
      }
    })
  }
  d.structure_log = JSON.stringify(d.structure_log)
}

export function initFormData (data, result) {
  result.structure_log = []
  if (typeof data.structure_log === 'string') {
    try {
      result.structure_log = JSON.parse(data.structure_log)
    } catch (e) {
      result.structure_log = []
    }
  }
  if (data.structure_log instanceof Array) {
    result.structure_log = data.structure_log.map(li => {
      return {
        ...li,
        begin_datetime: li.begin_datetime ? moment(li.begin_datetime * 1000) : undefined,
        end_datetime: li.end_datetime ? moment(li.end_datetime * 1000) : undefined
      }
    })
  }
}

function Main ({ fields }) {
  const [structureList, setStructureList] = useState([])
  const [occupationList, setOccupationList] = useState([])
  const visible = useMemo(() => {
    return fields.includes('structure_log')
  }, [fields])
  useEffect(() => {
    const d = new Dictionary()
    d.init('单位性质,仲裁员职业分类2')
      .then(() => {
        setStructureList(d.getList('单位性质').map(li => li.value))
        setOccupationList(d.getList('仲裁员职业分类2').map(li => li.value))
      })
      .catch(e => console.error(e))
  }, [])
  return (
    <div className={classNames({ [style.hide]: !visible })}>
      <div className={style.title}>工作经历</div>
      <List name='structure_log'>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              return (
                <Row key={key} gutter={24}>
                  <Col span={24}>
                    <div className={style['block-title']}>
                      <span>工作经历{name + 1}</span>
                      <Button shape='circle' icon={<DeleteOutlined />} onClick={() => remove(name)} />
                    </div>
                  </Col>
                  <Col span={24}>
                    <Item name={[name, 'structure']} label='单位' {...restField}>
                      <Input placeholder='请输入' allowClear />
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item name={[name, 'structure_type']} label='单位性质' {...restField}>
                      <Select placeholder='请选择' allowClear>
                        {structureList.map((li, i) => {
                          return <Option key={i} value={li}>{li}</Option>
                        })}
                      </Select>
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item name={[name, 'structure_post']} label='职务' {...restField}>
                      <Input placeholder='请输入' allowClear />
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item name={[name, 'begin_datetime']} label='开始时间' {...restField}>
                      <DatePicker placeholder='请选择' className={style.full} />
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item name={[name, 'end_datetime']} label='结束时间' {...restField}>
                      <DatePicker placeholder='至今' className={style.full} />
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item name={[name, 'occupation']} label='工作类别' {...restField}>
                      <Select placeholder='请选择' allowClear>
                        {occupationList.map((li, i) => {
                          return <Option key={i} value={li}>{li}</Option>
                        })}
                      </Select>
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item label='是否为目前主要从事的工作' name={[name, 'ismain']}>
                      <Radio.Group options={[{ label: '是', value: 1 }, { label: '否', value: 0 }]} />
                    </Item>
                  </Col>
                </Row>
              )
            })}
            <Col span={24}>
              <Item>
                <Button block type='dashed' icon={<PlusOutlined />} onClick={() => add()}>添加</Button>
              </Item>
            </Col>
          </>
        )}
      </List>
    </div>
  )
}

export default Main
