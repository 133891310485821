export const caseStatusList = ['收案', '交费', '立案', '移交送达', '送达完成', '移交办案', '已组庭', '已结案', '已结案送达', '已归档']
export const companyName = '厦门仲裁委员会'
export const companyUrl = 'www.xmac.org.cn'
export const appName = '云上厦仲'
export const arbitratorOccupation = ['律师', '企业', '公务员', '退休法官', '教学研究']

export const appVersion = '1'

export const pk = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC1IktOwr+4VSXGUmYfaqsfe1dT
VPKOW/LYQUHF7VBB75Pa5gMl+wI9CW2Y7th9F/9MFokQINzoZg1bsR157XOdO8bE
Vr00HBpFCtyHxw9jqWoaivDJlxsmYXdC24X46mWg8/E6LWGOXx98cTQ3k/K67NwD
r6JaRBJp1tkVcO4pkwIDAQAB
-----END PUBLIC KEY-----`

export const politicalList = ['中共党员', '中共预备党员', '共青团员', '民革党员', '民盟盟员', '民建会员', '民进会员', '农工党党员', '致公党党员', '九三学社社员', '台盟盟员', '无党派人士', '群众']
