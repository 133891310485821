import React, { useEffect, useState } from 'react'
import style from './Index.module.less'
import { Form, Select, Row, Col, Input } from 'antd'
import Dictionary from '@/assets/js/dictionary'

const { Item } = Form
const { Option } = Select
export function formatUpdateData (d) {

}

export function initFormData (data, result) {
  const keys = ['willingToDraftAward', 'annualDraftLimit', 'draftingSections', 'draftingSectionsRemark', 'externalArbitrationTasks', 'otherSuggestions']
  keys.forEach(k => {
    if (data[k] !== undefined && data[k] !== null) {
      result[k] = data[k]
    }
  })
}

function Main () {
  const [options1, setOptions1] = useState([])
  const [options2, setOptions2] = useState([])
  useEffect(() => {
    const d = new Dictionary()
    d.init('仲裁员草拟裁决书组成部分,每年裁决书编写数量')
      .then(() => {
        setOptions1(d.getList('仲裁员草拟裁决书组成部分').map(li => ({ label: li.value, value: li.value })))
        setOptions2(d.getList('每年裁决书编写数量').map(li => ({ label: li.value, value: li.value })))
      })
      .catch(e => console.error(e))
  }, [])
  return (
    <div>
      <div className={style.title}>其他信息</div>
      <Row gutter={24}>
        <Col span={12}>
          <Item label='是否愿意草拟裁决书' name='willingToDraftAward' rules={[{ required: true, message: '是否愿意草拟裁决书不能为空' }]}>
            <Select placeholder='请选择' allowClear>
              <Option value='愿意'>愿意</Option>
              <Option value='不愿意'>不愿意</Option>
            </Select>
          </Item>
        </Col>
        <Item noStyle shouldUpdate={(prev, cur) => prev.willingToDraftAward !== cur.willingToDraftAward}>
          {
            ({ getFieldValue }) => {
              const v = getFieldValue('willingToDraftAward')
              if (v === '愿意') {
                return (
                  <>
                    <Col span={12}>
                      <Item label='每年能写的裁决书的量' name='annualDraftLimit' rules={[{ required: true, message: '每年能写的裁决书的量不能为空' }]}>
                        <Select placeholder='请选择' options={options2} allowClear />
                      </Item>
                    </Col>
                    <Col span={12}>
                      <Item label='愿意草拟的部分' name='draftingSections' rules={[{ required: true, message: '愿意草拟的部分不能为空' }]}>
                        <Select placeholder='请选择' options={options1} allowClear />
                      </Item>
                    </Col>
                  </>
                )
              }
              return <span />
            }
          }
        </Item>
        <Item noStyle shouldUpdate={(prev, cur) => prev.draftingSections !== cur.draftingSections}>
          {
            ({ getFieldValue }) => {
              const v = getFieldValue('draftingSections')
              if (v === '其他') {
                return (
                  <Col span={12}>
                    <Item label='草拟的其他部分' name='draftingSectionsRemark' rules={[{ required: true, message: '草拟的其他部分不能为空' }]}>
                      <Input placeholder='请输入' allowClear />
                    </Item>
                  </Col>
                )
              }
              return <span />
            }
          }
        </Item>
        <Col span={12}>
          <Item label='办案外参与仲裁的工作' name='externalArbitrationTasks'>
            <Input placeholder='请输入' allowClear />
          </Item>
        </Col>
        <Col span={12}>
          <Item label='其他的意见和建议' name='otherSuggestions'>
            <Input placeholder='请输入' allowClear />
          </Item>
        </Col>
      </Row>
    </div>
  )
}

export default Main
