import { $get, $put } from '@/assets/js/authRequest'
import { decompress } from '@/assets/js/tool'
import { message } from 'antd'

export async function getDataRequest (id) {
  const res = await $get(`/api/arbitrator/me/getByAccountid/${id}`)
  if (res.status !== 1) {
    message.error(res.message)
    throw new Error(res.message)
  }
  if (res.data) {
    if (res.data.mobile && typeof res.data.mobile === 'string') {
      res.data.mobile = decompress(res.data.mobile)
    }
    if (res.data.license && typeof res.data.license === 'string') {
      res.data.license = decompress(res.data.license)
    }
    if (res.data.bank_accountno && typeof res.data.bank_accountno === 'string') {
      res.data.bank_accountno = decompress(res.data.bank_accountno)
    }
  }
  return res.data
}

export async function updateDataRequest (id, d) {
  const res = await $put(`/api/arbitrator/me/${id}`, d)
  if (res.status !== 1) {
    message.error(res.message)
    throw new Error(res.message)
  }
  return res.data
}

export async function getProfessionalTagsRequest () {
  const res = await $get('/api/arbitrator//professionaltag')
  if (res.status !== 1) {
    message.error(res.message)
    throw new Error(res.message)
  }
  return res.data
}
