import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import style from './Index.module.less'
import { getDataRequest, updateDataRequest, getProfessionalTagsRequest } from './ajax'
import { awaitWrap, compress, confirmAction } from '@/assets/js/tool'
import { message, Form, Button, Skeleton } from 'antd'
import BaseInfoTag, * as BaseInfo from './components/BaseInfo/Index'
import BankTag, * as Bank from './components/Bank/Index'
import EmergencyContactTag, * as EmergencyContact from './components/EmergencyContact/Index'
import ContactTag, * as Contact from './components/Contact/Index'
import AchievementTag, * as Achievement from './components/Achievement/Index'
import ProfessionalTag, * as Professional from './components/ProfessionalTag/Index'
import ResearchTag, * as Research from './components/ResearchTag/Index'
import EducationTag, * as Education from './components/Education/Index'
import WorkTag, * as Work from './components/Work/Index'
import QualificationTag, * as Qualification from './components/Qualification/Index'
import ProfessionalTags from '@/assets/js/professionalTags'
import { changeAllRequired } from './util'
import _ from 'lodash'
import { useSelector } from 'react-redux'

const blockList = ['个人信息', '联系方式', '教育背景', '工作经历', '仲裁员任职资格', '擅长专业', '研究领域', '学术成果', '报酬接收账号', '紧急联系人']

async function initData (id, setData, setLoading) {
  setLoading(true)
  const [e, d] = await awaitWrap(getDataRequest(id))
  setLoading(false)
  if (e === null && d !== null) {
    setData(d)
  }
}

async function updateData (id, data) {
  const hide = message.loading('保存中')
  await awaitWrap(updateDataRequest(id, data))
  hide()
}

async function initProfessionalTags (setTreeData, setTagObj) {
  const [e, d] = await awaitWrap(getProfessionalTagsRequest())
  if (e === null && d !== null) {
    setTreeData(d)
    setTagObj(new ProfessionalTags(d))
  }
}

async function onSave (id, data, tagObj, callback = () => {}) {
  const [e, d] = await awaitWrap(formatUpdateData(data))
  if (e !== null) {
    console.error(e)
    message.warning(e.message)
    return undefined
  }
  tagObj.initIdsToTreeList(d.professional_tag)
  const m = tagObj.getValidateError()
  if (m) {
    message.warning(m)
    return undefined
  }
  updateData(id, d).finally(() => { callback() }).catch(e => console.error(e))
}

async function formatUpdateData (d) {
  const keys = Object.keys(d)
  keys.forEach(key => {
    if (d[key] === undefined || d[key] === '') {
      d[key] = null
    }
  })
  BaseInfo.formatUpdateData(d)
  Contact.formatUpdateData(d)
  await Education.formatUpdateData(d)
  Work.formatUpdateData(d)
  await Qualification.formatUpdateData(d)
  Professional.formatUpdateData(d)
  Achievement.formatUpdateData(d)
  Research.formatUpdateData(d)
  Bank.formatUpdateData(d)
  EmergencyContact.formatUpdateData(d)
  if (d.mobile && typeof d.mobile === 'string') {
    d.mobile = compress(d.mobile)
  }
  if (d.license && typeof d.license === 'string') {
    d.license = compress(d.license)
  }
  if (d.bank_accountno && typeof d.bank_accountno === 'string') {
    d.bank_accountno = compress(d.bank_accountno)
  }
  return d
}

function initForm (data, formRef, fileSet, setEducationFileMap, setRequiredList) {
  const o = {}
  BaseInfo.initFormData(data, o)
  Contact.initFormData(data, o)
  Education.initFormData(data, o, setEducationFileMap)
  Work.initFormData(data, o)
  Qualification.initFormData(data, o, fileSet)
  Professional.initFormData(data, o)
  Achievement.initFormData(data, o)
  Research.initFormData(data, o)
  Bank.initFormData(data, o)
  EmergencyContact.initFormData(data, o)
  formRef.current.setFieldsValue(o)
  changeAllRequired(o, setRequiredList)
}

function scrollHandle (divRef, setActiveAnchor) {
  const o = window.document.documentElement
  if (o === null || !divRef.current) {
    return undefined
  }
  const top = o.scrollTop
  const cTop = o.offsetTop
  for (let i = 1; i < 11; i++) {
    const e = divRef.current.querySelector(`.block${i}`)
    if (e !== null) {
      if (e.offsetHeight > top - e.offsetTop + cTop) {
        setActiveAnchor(i - 1)
        break
      }
    }
  }
}

const scrollFn = _.debounce(scrollHandle, 100)

function Main ({ meta = {} }) {
  const [data, setData] = useState(null)
  const formRef = useRef(null)
  const divRef = useRef(null)
  const [status, setStatus] = useState('')
  const [treeData, setTreeData] = useState([])
  const [tagObj, setTagObj] = useState(null)
  const [fileList1, setfileList1] = useState([])
  const [fileList2, setfileList2] = useState([])
  const [fileList3, setfileList3] = useState([])
  const [fileList4, setfileList4] = useState([])
  const [fileList5, setfileList5] = useState([])
  const [educationFileMap, setEducationFileMap] = useState({})
  const [activeAnchor, setActiveAnchor] = useState(0)
  const id = useSelector(state => state.basic.userInfo.id)
  const [requiredList, setRequiredList] = useState([false, false, false, false, false])
  const [statisticVisible, setStatisticVisible] = useState(false)
  const [annualVisible, setAnnualVisible] = useState(false)
  const [version, setVersion] = useState(Date.now())
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (id) {
      initData(id, setData, setLoading)
    }
  }, [id, version])
  useEffect(() => {
    if (data && formRef.current) {
      initForm(data, formRef, [setfileList1, setfileList2, setfileList3, setfileList4, setfileList5], setEducationFileMap, setRequiredList)
      setStatus(data.status)
    }
  }, [data, formRef])
  useEffect(() => {
    initProfessionalTags(setTreeData, setTagObj)
    function scroll () {
      scrollFn(divRef, setActiveAnchor)
    }
    window.addEventListener('scroll', scroll)
    return () => {
      window.removeEventListener('scroll', scroll)
    }
  }, [])
  return (
    <div>
      <div ref={divRef} className={classNames(style.loading, { [style.hide]: !loading })}>
        <Skeleton active />
      </div>
      <div ref={divRef} className={classNames(style.container, { [style.hide]: loading })}>
        <div className={style['left-list']}>
          {blockList.map((li, i) => {
            return (
              <a
                className={classNames(style['left-list-item'], { [style.active]: activeAnchor === i && !statisticVisible && !annualVisible })} key={i} onClick={() => {
                  setActiveAnchor(i)
                  setAnnualVisible(false)
                  setStatisticVisible(false)
                  const o = divRef.current.querySelector(`.block${i + 1}`)
                  if (o) {
                    window.document.documentElement.scrollTop = o.offsetTop
                  }
                }}
              >
                <span>{li}</span>
              </a>
            )
          })}
        </div>
        <div className={classNames(style['right-container'], { [style.hide]: statisticVisible || annualVisible })}>
          <Form
            ref={formRef} layout='vertical' onFinish={d => onSave(id, d, tagObj, () => setVersion(Date.now()))} scrollToFirstError={{
              behavior: actions =>
                actions.forEach(({ el, top, left }) => {
                  if (el.scrollTop > top) {
                    el.scrollTop = top - 100
                  } else if (el.scrollTop < top) {
                    el.scrollTop = top + 100
                  } else {
                    el.scrollTop = top
                  }
                })
            }}
          >
            <div className='block1'>
              <BaseInfoTag />
            </div>
            <div className='block2'>
              <ContactTag />
            </div>
            <div className='block3'>
              <EducationTag fileMap={educationFileMap} setFileMap={setEducationFileMap} />
            </div>
            <div className='block4'>
              <WorkTag />
            </div>
            <div className='block5'>
              <QualificationTag formRef={formRef} fileList1={fileList1} fileList2={fileList2} fileList3={fileList3} fileList4={fileList4} fileList5={fileList5} setfileList1={setfileList1} setfileList2={setfileList2} setfileList3={setfileList3} setfileList4={setfileList4} setfileList5={setfileList5} requiredList={requiredList} setRequiredList={setRequiredList} status={status} />
            </div>
            <div className='block6'>
              <ProfessionalTag treeData={treeData} />
            </div>
            <div className='block7'>
              <ResearchTag />
            </div>
            <div className='block8'>
              <AchievementTag />
            </div>
            <div className='block9'>
              <BankTag />
            </div>
            <div className='block10'>
              <EmergencyContactTag />
            </div>
          </Form>
          <div className={style.btns}>
            <div className={style['btns-inner']}>
              <Button type='primary' onClick={() => confirmAction('您确认要保存吗？', () => { formRef.current.submit() })}>保存</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Main
