import React, { useMemo } from 'react'
import style from './Index.module.less'
import { Form, Input, Row, Col } from 'antd'
import classNames from 'classnames'

const { Item } = Form
const allFields = ['emergency_contact_structure', 'emergency_contact', 'emergency_contact_mobile']
export function formatUpdateData (d) {

}

export function initFormData (data, result) {
  const keys = ['emergency_contact_structure', 'emergency_contact', 'emergency_contact_mobile']
  keys.forEach(k => {
    if (data[k] !== undefined && data[k] !== null) {
      result[k] = data[k]
    }
  })
}

function Main ({ fields }) {
  const fieldMap = useMemo(() => {
    const o = {}
    allFields.forEach(key => {
      o[key] = false
    })
    fields.forEach(key => {
      if (allFields.includes(key)) {
        o[key] = true
      }
    })
    return o
  }, [fields])
  const visible = useMemo(() => {
    return allFields.some(key => fieldMap[key])
  }, [fieldMap])
  return (
    <div className={classNames({ [style.hide]: !visible })}>
      <div className={style.title}>紧急联系人</div>
      <Row gutter={24}>
        {
          fieldMap.emergency_contact_structure && (
            <Col span={12}>
              <Item label='工作单位' name='emergency_contact_structure'>
                <Input placeholder='请输入' allowClear />
              </Item>
            </Col>
          )
        }
        {
          fieldMap.emergency_contact && (
            <Col span={12}>
              <Item label='姓名' name='emergency_contact'>
                <Input placeholder='请输入' allowClear />
              </Item>
            </Col>
          )
        }
        {
          fieldMap.emergency_contact_mobile && (
            <Col span={12}>
              <Item label='手机号' name='emergency_contact_mobile'>
                <Input placeholder='请输入' allowClear />
              </Item>
            </Col>
          )
        }
      </Row>
    </div>
  )
}

export default Main
