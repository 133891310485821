import React from 'react'
import style from './Index.module.less'
import { Form, Row, Col } from 'antd'
import { awaitWrap } from '@/assets/js/tool'
import { getListRequest, saveAllTagsRequest } from './ajax'
import DebounceSelect from '@/components/DebounceSelect/Index'

const { Item } = Form

async function saveTags (tags) {
  await awaitWrap(saveAllTagsRequest(tags))
}

async function initTags (title) {
  const [e, d] = await awaitWrap(getListRequest(title))
  if (e === null && d !== null) {
    return d.list.map(li => {
      return {
        label: li.title,
        value: li.value
      }
    })
  }
  return []
}

export function formatUpdateData (d) {
  if (d.research_tag instanceof Array && d.research_tag.length > 0) {
    d.research_tag = d.research_tag.map(li => li.value).join(',')
    saveTags(d.research_tag).catch(e => console.error(e))
  }
}

export function initFormData (data, result) {
  if (typeof data.research_tag === 'string' && data.research_tag.length > 0) {
    result.research_tag = data.research_tag.split(',').filter(li => li).map(li => {
      return {
        label: li,
        value: li
      }
    })
  }
}

function Main () {
  return (
    <div>
      <div className={style.title}>研究领域</div>
      <Row gutter={24}>
        <Col span={24}>
          <Item label='研究领域' name='research_tag'>
            <DebounceSelect mode='tags' placeholder='请输入' fetchOptions={initTags} allowClear />
          </Item>
        </Col>
      </Row>
    </div>
  )
}

export default Main
