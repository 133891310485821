import React, { useCallback, useEffect, useMemo, useState } from 'react'
import style from './Index.module.less'
import { Form, Input, Row, Col, Button, DatePicker, Upload, message, Alert } from 'antd'
import { getUploadPropsFn } from '@/assets/js/tool'
import { UploadOutlined } from '@ant-design/icons'
import { submitFilesAndReturnFiles } from '@/assets/js/request'
import moment from 'moment'
import { changeRequired } from '../../util'
import classNames from 'classnames'

const { Item } = Form

export async function formatUpdateData (d) {
  const list = []
  const keys = ['qualification01', 'qualification02', 'qualification03', 'qualification04', 'qualification05']
  keys.forEach(key => {
    const o = d[key] || {}
    if (o.begin_datetime) {
      list.push({
        qualification: key,
        ...o
      })
    }
    delete d[key]
  })
  for (let i = 0; i < list.length; i++) {
    const o = list[i]
    formatDate(o)
    await uploadFile(o)
  }
  d.qualification_info = JSON.stringify(list)
}

function formatDate (d) {
  if (d.begin_datetime instanceof moment) {
    d.begin_datetime = Math.floor(d.begin_datetime.valueOf() / 1000)
  }
  if (d.end_datetime instanceof moment) {
    d.end_datetime = Math.floor(d.end_datetime.valueOf() / 1000)
  }
}

async function uploadFile (d) {
  let file = []
  if (d.file instanceof Array) {
    file = d.file
  } else if (d.file && d.file.fileList instanceof Array) {
    file = d.file.fileList
  }
  file = file.filter(li => li.name)
  d.file = file
  if (file.length) {
    const hide = message.loading('上传中')
    try {
      let fs = await submitFilesAndReturnFiles(file)
      fs = fs.map((f, i) => {
        if (typeof f === 'string') {
          return file[i]
        }
        f.name = file[i].name
        return f
      })
      d.file = fs
      d.fileid = d.file.filter(li => li.id).map(li => li.id).join(',')
      hide()
    } catch (e) {
      hide()
      return message.warning('上传图片失败')
    }
  }
}

export function initFormData (data, result, fileSet) {
  if (data.qualification_info instanceof Array) {
    data.qualification_info.forEach((li, i) => {
      if (typeof li.begin_datetime === 'number') {
        li.begin_datetime = moment(li.begin_datetime * 1000)
      }
      if (typeof li.end_datetime === 'number') {
        li.end_datetime = moment(li.end_datetime * 1000)
      }
      result[li.qualification] = { ...li }
      if (result[li.qualification].file instanceof Array) {
        const k = parseInt(li.qualification[li.qualification.length - 1]) - 1
        fileSet[k](result[li.qualification].file)
      }
    })
  }
}

// function changeRequired (formRef, setRequired, key, keys) {
//   const c = formRef.current
//   console.log(123)
//   if (!c) {
//     return undefined
//   }
//   const o = c[key] || {}
//   let status = false
//   for (const k in keys) {
//     if (o[k] !== undefined && o[k] !== null && (typeof o[k] instanceof Array && o[k].length > 0)) {
//       status = true
//       break
//     }
//   }
//   setRequired(status)
// }

function Main ({ formRef, fileList1, fileList2, fileList3, fileList4, fileList5, setfileList1, setfileList2, setfileList3, setfileList4, setfileList5, requiredList, setRequiredList, status, fields }) {
  const [required, setRequired] = useState(false)
  const visible = useMemo(() => {
    return fields.includes('qualification_info')
  }, [fields])
  const validateFn = useCallback((index) => {
    changeRequired(formRef, index, requiredList, setRequiredList)
  }, [formRef, requiredList, setRequiredList])
  useEffect(() => {
    setRequired(status !== '在聘')
  }, [status])
  return (
    <div className={classNames({ [style.hide]: !visible })}>
      <div className={style.title}>仲裁员任职资格</div>
      <div className={style.notice}>
        <Alert type='warning' description='注意：从事仲裁工作满八年不包括担任仲裁员满八年的情形' showIcon />
      </div>
      <Row gutter={24}>
        <Col span={24}>
          <div className={style['block-title']}>
            <span>从事仲裁工作满 8 年</span>
          </div>
        </Col>
        <Col span={24}>
          <Item label='机构名称' name={['qualification01', 'structure']} rules={[{ required: requiredList[0], message: '机构名称不能为空' }]}>
            <Input placeholder='请输入' allowClear onBlur={() => validateFn(0)} />
          </Item>
        </Col>
        <Col span={12}>
          <Item name={['qualification01', 'begin_datetime']} label='开始时间' rules={[{ required: requiredList[0], message: '开始时间不能为空' }]}>
            <DatePicker placeholder='请选择' className={style.full} onChange={() => validateFn(0)} />
          </Item>
        </Col>
        <Col span={12}>
          <Item name={['qualification01', 'end_datetime']} label='结束时间'>
            <DatePicker placeholder='至今' className={style.full} onChange={() => validateFn(0)} />
          </Item>
        </Col>
        <Col span={24}>
          <Item name={['qualification01', 'file']} label='证明材料' rules={[{ required: requiredList[0] && required, message: '证明材料不能为空' }]}>
            <Upload {...getUploadPropsFn.call(this, formRef, '', fileList1, setfileList1)} onChange={() => validateFn(0)}>
              <Button>
                <UploadOutlined /> 上传文件
              </Button>
            </Upload>
          </Item>
        </Col>

        <Col span={24}>
          <div className={style['block-title']}>
            <span>从事律师工作满 8 年</span>
          </div>
        </Col>
        <Col span={24}>
          <Item label='律师证号' name={['qualification02', 'post_license']} rules={[{ required: requiredList[1], message: '律师证号不能为空' }]}>
            <Input placeholder='请输入' allowClear onBlur={() => validateFn(1)} />
          </Item>
        </Col>
        <Col span={12}>
          <Item name={['qualification02', 'begin_datetime']} label='开始时间' rules={[{ required: requiredList[1], message: '开始时间不能为空' }]}>
            <DatePicker placeholder='请选择' className={style.full} onChange={() => validateFn(1)} />
          </Item>
        </Col>
        <Col span={12}>
          <Item name={['qualification02', 'end_datetime']} label='结束时间'>
            <DatePicker placeholder='至今' className={style.full} onChange={() => validateFn(1)} />
          </Item>
        </Col>
        <Col span={24}>
          <Item name={['qualification02', 'file']} label='证明材料' rules={[{ required: requiredList[1] && required, message: '证明材料不能为空' }]}>
            <Upload {...getUploadPropsFn.call(this, formRef, '', fileList2, setfileList2)} onChange={() => validateFn(1)}>
              <Button>
                <UploadOutlined /> 上传文件
              </Button>
            </Upload>
          </Item>
        </Col>

        <Col span={24}>
          <div className={style['block-title']}>
            <span>曾任审判员满 8 年</span>
          </div>
        </Col>
        <Col span={24}>
          <Item label='机构名称' name={['qualification03', 'structure']} rules={[{ required: requiredList[2], message: '机构名称不能为空' }]}>
            <Input placeholder='请输入' allowClear onBlur={() => validateFn(2)} />
          </Item>
        </Col>
        <Col span={12}>
          <Item name={['qualification03', 'begin_datetime']} label='开始时间' rules={[{ required: requiredList[2], message: '开始时间不能为空' }]}>
            <DatePicker placeholder='请选择' className={style.full} onChange={() => validateFn(2)} />
          </Item>
        </Col>
        <Col span={12}>
          <Item name={['qualification03', 'end_datetime']} label='结束时间'>
            <DatePicker placeholder='至今' className={style.full} onChange={() => validateFn(2)} />
          </Item>
        </Col>
        <Col span={24}>
          <Item name={['qualification03', 'file']} label='证明材料' rules={[{ required: requiredList[2] && required, message: '证明材料不能为空' }]}>
            <Upload {...getUploadPropsFn.call(this, formRef, '', fileList3, setfileList3)} onChange={() => validateFn(2)}>
              <Button>
                <UploadOutlined /> 上传文件
              </Button>
            </Upload>
          </Item>
        </Col>

        <Col span={24}>
          <div className={style['block-title']}>
            <span>从事法律研究、教学工作具有高级职称</span>
          </div>
        </Col>
        <Col span={24}>
          <Item label='职称类别' name={['qualification04', 'structure_post']} rules={[{ required: requiredList[3], message: '职称类别不能为空' }]}>
            <Input placeholder='请输入' allowClear onBlur={() => validateFn(3)} />
          </Item>
        </Col>
        <Col span={24}>
          <Item label='证书证号' name={['qualification04', 'post_license']} rules={[{ required: requiredList[3], message: '证书证号不能为空' }]}>
            <Input placeholder='请输入' allowClear onBlur={() => validateFn(3)} />
          </Item>
        </Col>
        <Col span={12}>
          <Item name={['qualification04', 'begin_datetime']} label='获得时间' rules={[{ required: requiredList[3], message: '获得时间不能为空' }]}>
            <DatePicker placeholder='请选择' className={style.full} onChange={() => validateFn(3)} />
          </Item>
        </Col>
        <Col span={24}>
          <Item name={['qualification04', 'file']} label='证明材料' rules={[{ required: requiredList[3] && required, message: '证明材料不能为空' }]}>
            <Upload {...getUploadPropsFn.call(this, formRef, '', fileList4, setfileList4)} onChange={() => validateFn(3)}>
              <Button>
                <UploadOutlined /> 上传文件
              </Button>
            </Upload>
          </Item>
        </Col>

        <Col span={24}>
          <div className={style['block-title']}>
            <span>具有法律知识、从事经济贸易专业工作并具有高级职称或具有同等专业水平</span>
          </div>
        </Col>
        <Col span={24}>
          <Item label='职称类别' name={['qualification05', 'structure_post']} rules={[{ required: requiredList[4], message: '职称类别不能为空' }]}>
            <Input placeholder='请输入' allowClear onBlur={() => validateFn(4)} />
          </Item>
        </Col>
        <Col span={24}>
          <Item label='证书证号' name={['qualification05', 'post_license']} rules={[{ required: requiredList[4], message: '证书证号不能为空' }]}>
            <Input placeholder='请输入' allowClear onBlur={() => validateFn(4)} />
          </Item>
        </Col>
        <Col span={12}>
          <Item name={['qualification05', 'begin_datetime']} label='获得时间' rules={[{ required: requiredList[4] && required, message: '获得时间不能为空' }]}>
            <DatePicker placeholder='请选择' className={style.full} onChange={() => validateFn(4)} />
          </Item>
        </Col>
        <Col span={24}>
          <Item name={['qualification05', 'file']} label='证明材料' rules={[{ required: requiredList[4], message: '证明材料不能为空' }]}>
            <Upload {...getUploadPropsFn.call(this, formRef, '', fileList5, setfileList5)} onChange={() => validateFn(4)}>
              <Button>
                <UploadOutlined /> 上传文件
              </Button>
            </Upload>
          </Item>
        </Col>
      </Row>
    </div>
  )
}

export default Main
