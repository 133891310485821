import React, { useEffect, useMemo, useState } from 'react'
import style from './Index.module.less'
import { Alert } from 'antd'

function renderTime (t) {
  const s = []
  const d = Math.floor(t / 86400)
  t -= d * 86400
  const h = Math.floor(t / 3600)
  t -= h * 3600
  const m = Math.floor(t / 60)
  t -= m * 60
  if (d > 0) {
    s.push(`${d}天`)
  } else {
    s.push('0天')
  }
  if (h > 0) {
    s.push(`${h}时`)
  } else {
    s.push('0时')
  }
  if (m > 0) {
    s.push(`${m}分`)
  } else {
    s.push('0分')
  }
  if (t > 0) {
    s.push(`${t}秒`)
  } else {
    s.push('0秒')
  }
  return s.join('')
}
function Main ({ data }) {
  const [time, setTime] = useState('')
  const end = useMemo(() => {
    if (data && data.annual_end_datetime) {
      return data.annual_end_datetime
    }
    return 0
  }, [data])
  useEffect(() => {
    let time
    function loop () {
      const n = Math.floor(Date.now() / 1000)
      const s = end - n
      if (s >= 0) {
        setTime(`${renderTime(s)}`)
        time = setTimeout(() => {
          loop()
        }, 1000)
      } else {
        setTime('0天0时0分0秒')
      }
    }
    loop()
    return () => {
      if (time) {
        clearTimeout(time)
      }
    }
  }, [end])
  if (!data) {
    return <span />
  }
  return (
    <div className={style.container}>
      <Alert
        // message='提示'
        description={(
          <div>
            {data.noticeinfo && <div>{data.noticeinfopc}</div>}
            <div className={style['time-notice']}>距年审截止期限剩余时间：<span className={style.time}>{time}</span></div>
          </div>
        )}
        type='warning'
        showIcon
      />
    </div>
  )
}

export default Main
